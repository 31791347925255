<!-- eslint-disable -->
<template>
  <loading :active="isLoading" :is-full-page="fullPage" />
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header card-default">
          <div class="row">
            <div class="col-md-6">
              <p id="idExsampel" class="text-muted">Entri Kartu Rencana Studi Mahasiswa </p>
            </div>
          </div>
        </div>
        <div class="card-body padding-t-0 ">
          <div class="widget white-bg">
            <div class="row">
              <div class="col-md-4 col-xs-6 b-r">
                <strong>{{ profil.nama_mahasiswa }}</strong>
                <br>
                <p class="text-muted">
                  {{ userData.username }}
                </p>
              </div>
              <div class="col-md-4 col-xs-6 b-r">
                <strong>Program Studi</strong>
                <br>
                <p class="text-muted">
                  {{ resourceMhs.jenj_didik + ' ' + resourceMhs.nama_prodi }}
                </p>
              </div>
              <div class="col-md-2 col-xs-6 b-r">
                <strong>Tahun Akademik</strong>
                <br>
                <p class="text-muted">
                  {{ dataSMT.smt_nm }}
                </p>
              </div>
              <div class="col-md-2 col-xs-6">
                <strong>Jumlah SKS</strong>
                <br>
                <p class="text-muted">
                  {{ jumlahSKS }}
                </p>
              </div>
            </div>
          </div>
          <template v-if="!isLoading">
            <div v-show="isError" class="alert bg-danger alert-dismissible" role="alert" >
              <button type="button" class="close" data-dismiss="alert" aria-label="Close" >
                <span aria-hidden="true">×</span>
              </button>
              {{ isMessages }}
            </div>
            <!-- Component status KRS -->
            <krs-status v-if="!isEntri" :validasi="statusValidasi"></krs-status>
            <div v-if="isEntri" class="widget white-bg friends-group clearfix" v-show="!isLoading">
              <small class="text-muted">IP Semester Kemarin</small>
              <p>{{ ipsKemarin }}</p>
              <small class="text-muted">Jumlah SKS yang dapat di ambil semester ini </small>
              <p>{{ makEntri }} S K S</p>

              <div v-show="isEntriWarning" class="alert bg-danger alert-dismissible" role="alert" >
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" >
                  <span aria-hidden="true">×</span>
                </button>
                Maaf!.. tidak dapat mengambil lebih dari {{ makEntri }} S K S
              </div>
            </div>
          </template>

          <table v-show="isDataKrs" class="table">
            <thead>
              <tr>
                <th>#</th>
                <th>KODE</th>
                <th>NAMA MATAKULIAH</th>
                <th>S K S</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(krs, num) in dataKRS"
                :key="krs.id_nil"
              >
                <td>{{ num + 1 }}</td>
                <td>{{ krs.kd_mak }}</td>
                <td>{{ krs.nm_mak }}</td>
                <td>{{ krs.mk_sks }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <template v-if="!isLoading">
        <div v-show="isEntri" class="card">
          <div class="card-header padding-b-0">
            <div class="row">
              <div class="col-md-6">
                <p class="text-muted">
                  Daftar matakuliah yang dapat di ambil
                </p>
              </div>
              <div class="col-md-6">
                <button type="button" class="btn btn-success btn-border box-shadow btn-circle pull-right" @click="validasiKRS" >
                  <i class="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="scrollDiv">
              <ul class="todo-list">
                <li
                  v-for="(kur, num) in dataKur"
                  :key="kur.id_mk"
                >
                  <div class="checkbox checkbox-purple margin-r-5">
                    <input
                      :id="'checkbox' + num"
                      v-model="valKRS.kurikulum"
                      type="checkbox"
                      :value="kur"
                    >
                    <label :for="'checkbox' + num">
                      <span
                        aria-hidden="true"
                        class="icon-notebook fs-18"
                      />
                      <span class="fs-upper400 mw-100">{{ num + 1 }}.  {{ kur.kd_mak }}</span>
                      <span class="fs-upper400 mw-100 margin-l-22">{{ kur.nm_mak + ' - ( ' + kur.mk_sks + ' S K S )' }}</span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-footer">
            Jumlah S K S yang di ambil {{ totalSKS }} S K S.
          </div>
          <!--
            <div class="card-footer">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Add Todo...">
                <span class="input-group-btn">
                  <button class="btn btn-default" type="button">
                    Add</button>
                </span>
              </div>
            </div>
            -->
        </div>
      </template>
      <div v-show="isValidasi" class="card" >
        <div class="card-header padding-b-0">
          <div class="row">
            <div class="col-md-7">
              <p class="text-muted">
                Daftar matakuliah yang telah dipilih
              </p>
            </div>
            <div class="col-md-5">
              <button type="button" class="btn btn-success box-shadow btn-rounded pull-right"  @click="konfirmasiKRS" >
                Ajukan KRS ini
              </button>
              <button type="button" class="btn btn-warning box-shadow btn-rounded pull-right" @click="entriKrs" > Kembali </button> &nbsp;
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="scrollDiv">
            <ul class="todo-list">
              <li v-for="(val, num) in valKRS.kurikulum" :key="val.id_mk" >
                <div class="checkbox checkbox-success margin-r-5">
                  <input :id="'checkbox' + num" type="checkbox" checked="" >
                  <label>
                    <span aria-hidden="true"  class="icon-notebook fs-18"></span>
                    <span class="fs-upper400 mw-100">{{ num + 1 }}. {{ val.kd_mak }}</span>
                    <span class="fs-upper400 mw-100 margin-l-22"> - {{ val.nm_mak + ' - ( ' + val.mk_sks + ' S K S )' }}</span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-footer">
          Jumlah S K S yang di ambil {{ totalSKS }} S K S.
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade konfirmasi-modal"  tabindex="-1"  role="dialog"  aria-labelledby="myDefaultModalLabel" >
    <div class="modal-dialog" role="document" >
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
            <span aria-hidden="true" class="fa fa-times" />
          </button>
          <h5 id="myDefaultModalLabel" class="modal-title" > Konfirmasi </h5>
        </div>
        <div class="modal-body">
          <div class="widget white-bg">
            <div class="row">
              <div class="col-md-12 col-xs-12">
                <strong>Pastikan matakuliah yang dipilih sudah sesuai!.</strong>
                <br>
                <p class="text-muted">
                  <small>Jumlah S K S yang diambil semester ini {{ totalSKS }} S K S</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-dismiss="modal" >
            No, Tidak
          </button>
          <button type="button" class="btn btn-success" @click="prosesKRS" >
            Yes, Ajukan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
/* global $ */
import KrsStatus from '@/components/krs/KrsStatus.vue'
import Loading from 'vue-loading-overlay'
import { getMhs } from '@/services/mahasiswa.service'
import { getKRS, getKurikulum, addKRS } from '@/services/krs.service'
import { useStore } from 'vuex'
export default {
  name: 'EntriKrs',
  components: {
    Loading,
    KrsStatus
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userData: users,
      dataKRS: [],
      dataKur: [],
      dataSMT: {},
      statusKRS: {},
      makEntri: 0,
      ipsKemarin: 0,
      valKRS: {
        kurikulum: []
      },
      totKredit: 0,
      profil: {},
      fullPage: true,
      loadMhs: false,
      loadKur: false,
      loadKrs: false,
      isDataKrs: false,
      isEntri: true,
      isError: false,
      isValidasi: false,
      errorData: {},
      isMessages: ''
    }
  },
  computed: {
    resourceMhs: function () {
      return this.profil
    },
    statusValidasi: function () {
      return this.statusKRS
    },
    isLoading: function () {
      const load = !!((this.loadMhs || this.loadKur || this.loadKrs))
      return load
    },
    totalSKS: function () {
      var totKredit = 0
      const pilihMk = this.valKRS.kurikulum
      $.each(pilihMk, function (index, value) {
        totKredit = totKredit + Number(value.mk_sks)
      })
      return totKredit
    },
    isEntriWarning: function () {
      const jumAdd = this.totalSKS
      return (jumAdd > this.makEntri)
    },
    jumlahSKS: function () {
      var totKredit = 0
      const entriMHS = this.dataKRS
      $.each(entriMHS, function (index, value) {
        totKredit = totKredit + Number(value.mk_sks)
      })
      return totKredit
    }
  },
  created () {
    this.dataMahasiswa()
  },
  mounted () {
    $('.scrollDiv').slimScroll({
      color: '#eee',
      size: '5px',
      height: '420px',
      alwaysVisible: false
    })
  },
  methods: {
    async dataMahasiswa () {
      this.loadMhs = true
      getMhs({ nipd: this.userData.username })
        .then((response) => {
          this.profil = response.records
          this.loadMhs = false

          this.getData()
        })
        .catch((error) => {
          this.errorData = (error.response) ? {} : {}
          this.loadMhs = false
        })
    },
    async getData () {
      this.loadKrs = true
      const params = {
        id_reg_pd: this.resourceMhs.id_mahasiswa,
        th_smtmasuk: this.resourceMhs.id_mulai_masuk_smt
      }
      getKRS(params).then(response => {
        if (response.error_code === 200) {
          this.dataKRS = response.records
          this.dataSMT = response.tahun_akademik
          this.statusKRS = response.validasi
          this.makEntri = response.maksimal_kredit
          this.ipsKemarin = response.ips_sem_kemarin
          if (this.statusKRS.length !== 0) {
            this.isDataKrs = true
            this.isEntri = false
          } else {
            if (response.entrikrs === false) {
              this.isError = true
              this.isDataKrs = true
              this.isMessages = 'Pengisian KRS sudah ditutup'
            }
            this.statusKRS = { status_acc: '0', tanggal: '', jam: '' }
          }
          if (!this.isDataKrs) { this.getKurikulum() }
        } else {
          this.isError = true
          this.isMessages = response.messages
        }
        this.loadKrs = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadKrs = false
      })
    },
    async getKurikulum () {
      this.loadKur = true
      const params = {
        idmhs: this.profil.id_mahasiswa,
        paralel: this.profil.paralel_kurikulum,
        sms: this.profil.id_prodi,
        kurikulum: this.profil.tahun_kurikulum,
        smt_mahasiswa: this.dataSMT.smt_mhs,
        smt_akademik: this.dataSMT.smt_sem
      }
      getKurikulum(params).then(response => {
        if (response.error_code === 200) {
          this.dataKur = response.records
          this.loadKur = false
        }
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.loadKur = false
      })
    },
    async entriKrs () {
      this.isEntri = true
      this.isValidasi = false
    },
    async validasiKRS () {
      const dataMK = this.valKRS.kurikulum
      if (dataMK.length > 0) {
        if (this.isEntriWarning) {
          $.toast({
            heading: 'Warning',
            text: 'SKS yang diambil melebihi prasarat',
            position: 'top-right',
            loaderBg: '#fff',
            icon: 'error',
            hideAfter: 3000,
            stack: 1
          })
        } else {
          this.isEntri = false
          this.isValidasi = true
        }
      } else {
        $.toast({
          heading: 'Error !',
          text: 'Tidak ada matakuliah yang dipilih',
          position: 'top-right',
          loaderBg: '#fff',
          icon: 'error',
          hideAfter: 3000,
          stack: 1
        })
      }
    },
    async konfirmasiKRS () {
      const dataMK = this.valKRS.kurikulum
      if (dataMK.length > 0) {
        $('.konfirmasi-modal').modal('show')
      } else {
        $.toast({
          heading: 'Error !',
          text: 'Tidak ada matakuliah yang dipilih',
          position: 'top-right',
          loaderBg: '#fff',
          icon: 'error',
          hideAfter: 3000,
          stack: 1
        })
      }
    },
    async prosesKRS () {
      this.isLoading = true
      const params = {
        mhs_id: this.profil.id_mahasiswa,
        smt_id: this.dataSMT.smt_id,
        sms_id: this.profil.id_prodi,
        kurikulum: this.valKRS.kurikulum
      }
      addKRS(params).then(response => {
        if (response.error_code === 200) {
          $('.konfirmasi-modal').modal('hide')
          this.isEntri = false
          this.isValidasi = false
          this.isDataKrs = true

          this.getData()
        }
        this.isLoading = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoading = false
      })
    }
  }
}
</script>
