<!-- eslint-disable -->
<template>
  <slot name="header"></slot>
  <div class="widget white-bg friends-group clearfix" v-show="!isLoading">
    <small class="text-muted">Dosen Pembimbing Akademik</small>
    <p>{{ pembimbing.nm_sdm }}</p>
    <small class="text-muted">Status Persetujuan KRS </small>
    <p>
      <span v-show="(validasi.status_acc === '1')" class="badge badge-success">KRS SUDAH DISETUJUI</span>
      <span v-show="!(validasi.status_acc === '1')" class="badge badge-danger">Belum di Setujui Dosen Pembimbing</span>
    </p>
    <small class="text-muted">Tanggal Pengajuan KRS</small>
    <p>Tanggal. {{ validasi.tanggal }} Pukul. {{ validasi.jam }}</p>
  </div>
</template>
<script>
import { getPembimbing } from '@/services/mahasiswa.service'
import { defineComponent, reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  props: {
    validasi: Object
  },
  emits: ['show-table'],
  setup (props, { attrs, slots, emit, expose }) {
    const store = useStore()
    const users = store.getters.user
    const state = reactive({
      pembimbing: {},
      status: props.validasi.status_acc,
      isLoading: false
    })
    const handlePembimbing = () => {
      state.isLoading = true
      getPembimbing({ id_reg_pd: users.id_reg_pd }).then(response => {
        state.pembimbing = response?.record || []
        state.isLoading = false
      })
    }
    const loading = computed(() => {
      return state.isLoading
    })
    return {
      ...toRefs(state),
      loading,
      handlePembimbing
    }
  },
  created () {
    this.handlePembimbing()
  }
})
</script>
